<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img src="img/avatars/generic.png" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader>{{ username }}</CDropdownHeader>

    <!-- <CDropdownItem>
      <MIcon class="pr-2" name="fa-question-circle" />Help
    </CDropdownItem>-->

    <CDropdownDivider />
    <CDropdownItem @click.stop.prevent="doLogout">
      <CIcon name="cil-lock-locked" />Logout
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownHeader>Version {{ version }}</CDropdownHeader>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { VUE_APP_DISTRIBUTION_VERSION } from '@/utils/env';
export default {
  name: 'TheHeaderDropdownAccnt',
  computed: {
    ...mapGetters('auth', ['user']),
    version() {
      let distVersion = VUE_APP_DISTRIBUTION_VERSION;
      let parts = distVersion.split('.');
      if (parts.length >= 2) {
        return `${parts[0]}.${parts[1]}`;
      } else {
        return distVersion;
      }
    },
    username() {
      if (!this.user) {
        return '';
      }
      return this.user.username;
    },
  },
  methods: {
    doLogout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login', params: {} });
      });
    },
  },
  data() {
    return {
      itemsCount: 42,
    };
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
