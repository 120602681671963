export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'My Activities',
        to: '/tasks/activities',
        icon: 'cil-inbox',
      },
      // Clients
      {
        _name: 'CSidebarNavDropdown',
        name: 'Clients',
        icon: 'cil-people',
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'New Client',
            to: '/admin/clients/create',
            icon: 'cil-star',
            _requiresAdmin: true,
          },
          // Clients list dynamically inserts at the end.
        ],
      },
      // Knowledgebase
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Knowledgebase',
      //   icon: 'cil-book',
      //   _children: [
      //     {
      //       _name: 'CSidebarNavDropdown',
      //       name: 'Run Reports',
      //       icon: 'cil-recycle',
      //       _children: [
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'Deletion Log',
      //           to: '/',
      //         },
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'Upload Log',
      //           to: '/'
      //         },
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'DBA Functional Info',
      //           to: '/'
      //         },
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'DBA Technical Info',
      //           to: '/'
      //         }
      //       ]
      //     },
      //     {
      //       _name: 'CSidebarNavDropdown',
      //       name: 'View Files',
      //       icon: 'cil-recycle',
      //       _children: [
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'Knowledgebase',
      //           to: '/files/view/category/knowledgebase'
      //         },
      //         {
      //           _name: 'CSidebarNavItem',
      //           name: 'Admin',
      //           to: '/files/view/category/admin'
      //         }
      //       ]
      //     }
      //   ]
      // },
      // Admin Section
      {
        _name: 'CSidebarNavDropdown',
        name: 'Admin',
        to: '/admin',
        icon: 'cil-settings',
        _requiresAdmin: true,
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'Clients',
            to: '/admin/clients',
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Users',
            _children: [
              {
                _name: 'CSidebarNavItem',
                name: 'View All',
                to: '/admin/users',
              },
              // {
              //   _name: 'CSidebarNavItem',
              //   name: 'Create User',
              //   to: '/admin/user/create',
              // },
            ],
          },
        ],
      },
      // Workflows
      {
        _name: 'CSidebarNavDropdown',
        name: 'Workflows',
        icon: 'cil-list',
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'Running',
            to: '/workflow/running',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'All',
            to: '/workflow/all',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Definitions',
            to: '/workflow/definitions',
            _requiresAdmin: true,
          },
        ],
      },
    ],
  },
];
