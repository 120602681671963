<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.coreui.darkMode }">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view />
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
      <CModal
        color="danger"
        :show="showIdlePrompt"
        :close-on-backdrop="false"
        centered
      >
        <template #header>
          <h5>Session Timeout</h5>
        </template>
        <p>
          Your session will expire in less than
          <span class="idle-label" :class="{ 'text-danger': idleDelta < 10 }">{{
            idleLabel
          }}</span>
        </p>
        <p>
          Please click <em>"Continue"</em> to keep working or
          <em>"Log Out"</em> to end your session now.
        </p>
        <template #footer>
          <CButton color="danger" class="mr-4" variant="ghost" @click="logOff">
            Log Out
          </CButton>
          <CButton color="primary" @click="refreshToken"> Continue </CButton>
        </template>
      </CModal>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import { mapGetters } from 'vuex';
import { VUE_APP_IDLE_PROMPT } from '@/utils/env';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      idleLabel: '1 minute',
      idleInterval: null,
      idleDelta: 90,
      idleStart: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['showIdlePrompt', 'lastActivity']),
  },
  watch: {
    '$store.state.auth.showIdlePrompt': function () {
      if (this.showIdlePrompt) {
        const self = this;
        if (this.idleInterval) {
          try {
            clearInterval(this.idleInterval);
          } catch (_) {
            // Ignore
          }
        }

        const idleStart = new Date().getTime();

        this.idleInterval = setInterval(() => {
          const delta = new Date().getTime() - idleStart;
          const totalSeconds = Math.floor((VUE_APP_IDLE_PROMPT - delta) / 1000);
          self.idleDelta = totalSeconds;

          const mins = Math.floor(totalSeconds / 60);
          const secs = totalSeconds - mins * 60;
          if (mins > 0) {
            if (secs > 0) {
              self.idleLabel = `${mins} minute${
                mins > 1 ? 's' : ''
              } and ${self.secsPad(secs)} seconds`;
            } else {
              self.idleLabel = `${mins} minute${mins > 1 ? 's' : ''}`;
            }
          } else {
            self.idleLabel = `${secs} seconds`;
          }

          if (totalSeconds < 1) {
            self.logOff();
          }
        }, 1000);
      } else {
        clearInterval(this.idleInterval);
        this.idleLabel = '1 minute';
      }
    },
  },
  methods: {
    secsPad(num) {
      if (num > 0 && num < 10) {
        return `0${num}`;
      } else {
        return `${num}`;
      }
    },
    refreshToken() {
      this.$store.dispatch('auth/refreshToken').then(() => {
        this.$store.dispatch('auth/updateLastActivity');
      });
    },
    logOff() {
      try {
        clearInterval(this.idleInterval);
      } catch (_) {
        // Ignore
      }
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.idle-label {
  font-size: 14px;
  &.text-danger {
    font-weight: bold;
  }
}
</style>
