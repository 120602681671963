<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('coreui/toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('coreui/toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img src="img/brand/modios-base.svg" width="128" alt="Modios Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownUtilities />
    </CHeaderNav>
    <CHeaderNav>
      <!--
      <CHeaderNavItem class="px-3">
        <button class="c-header-nav-btn" @click="() => $store.commit('coreui/toggle', 'darkMode')">
          <CIcon v-if="$store.state.coreui.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      -->
      <!--
      <TheHeaderDropdownNotif />
      <TheHeaderDropdownTasks />
      <TheHeaderDropdownMssgs />
      -->
      <TheHeaderDropdownAccnt />

      <!-- <CHeaderNavItem class="px-3">
        <button
          in-header
          class="c-header-nav-btn"
          @click="$store.commit('coreui/toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem>-->
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';
// import TheHeaderDropdownNotif from './TheHeaderDropdownNotif';
// import TheHeaderDropdownTasks from './TheHeaderDropdownTasks';
// import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs';
import TheHeaderDropdownUtilities from './TheHeaderDropdownUtilities';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    // TheHeaderDropdownNotif,
    // TheHeaderDropdownTasks,
    // TheHeaderDropdownMssgs,
    TheHeaderDropdownUtilities,
  },
};
</script>
