<template>
  <CFooter :fixed="false">
    <!-- <div>
      <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span>
    </div> -->
    <div class="ml-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://cordiance.com">Modios</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
