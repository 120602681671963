<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="
      (value) => $store.commit('coreui/set', ['sidebarShow', value])
    "
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="img/brand/modios-base-white.svg"
        class="c-sidebar-brand-full"
        style="width: 118px"
        alt="Modios Logo"
      />
      <img
        src="img/brand/cordiance-signet.svg"
        class="c-sidebar-brand-minimized"
        style="width: 50px"
        alt="Cordiance Signet"
      />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('coreui/toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import DEFAULT_NAV from './_nav';
import { map } from 'lodash';

function removeAdminEntries(items) {
  return items.filter((i) => {
    if (i._requiresAdmin) {
      return false;
    }
    if (!!i._children && i._children.length > 0) {
      i._children = removeAdminEntries(i._children);
    }
    return true;
  });
}
export default {
  name: 'TheSidebar',
  computed: {
    ...mapGetters('clients', ['clientsResults']),
    ...mapGetters('auth', ['isAdmin']),
    clientNavItems() {
      return this.clientsResults.map((c) => {
        return this.makeClientEntry(c);
      });
    },
    nav() {
      let modifiedItems = this.getNavItems().map((item) => {
        // Add clientNavItems to the 'Clients' nav item block.
        if (
          item.name &&
          item.name == 'Clients' &&
          this.clientsResults.length > 0
        ) {
          // Remove any existing client entries from the nav to prevent duplicates, but don't remove non-client entries.
          let clientItems = item._children.filter((c) => {
            return 'client' !== c._type;
          });

          this.clientNavItems.forEach((c) => {
            clientItems.push(c);
          });

          item._children = clientItems;
        }

        return item;
      });

      if (!this.isAdmin) {
        modifiedItems = removeAdminEntries(modifiedItems);
      }

      return [{ _name: 'CSidebarNav', _children: modifiedItems }];
    },
    show() {
      return this.$store.state.coreui.sidebarShow;
    },
    minimize() {
      return this.$store.state.coreui.sidebarMinimize;
    },
  },
  methods: {
    getNavItems() {
      // _nav.js has an array with one object, and we just want the children of that first object.
      return JSON.parse(JSON.stringify(DEFAULT_NAV))[0]._children;
    },
    makeClientEntry(client) {
      return {
        _name: 'CSidebarNavItem',
        name: client.clientCode,
        to: `/client/${client.clientCode}/overview`,
        // Add this _type so we can filter these out later for de-dupe, etc.
        _type: 'client',
      };
    },
  },
};
</script>
