<template>
  <CDropdown>
    <template #toggler>
      <CHeaderNavLink>Utilities</CHeaderNavLink>
    </template>
    <template #default>
      <CDropdownHeader tag="div" class="text-center bg-light">
        Reports
      </CDropdownHeader>
      <CDropdownItem @click="showQuickLook"> Rapid Insight </CDropdownItem>
    </template>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownUtilities',
  methods: {
    showQuickLook() {
      this.$router.push({ name: 'quick-look-report' });
    },
  },
};
</script>
